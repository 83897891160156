import { useTheme } from '@emotion/react';
import AngleRightSVG from '../../public/img/angle-right.svg';
import { PlaceCard } from './place-card';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { Link } from '../styled/Link';
import { Place } from '../../models';

export function Places({
  title,
  places,
  href,
}: {
  title: string;
  href: string;
  places: { results: Place[] };
}) {
  const theme = useTheme();
  if (places.results.length === 0) {
    return null;
  }
  return (
    <div
      // page__viewed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        marginBottom: '4rem',
        ...theme.mixins.row(),
        [DEVICE_SCREENS_MAX_STRING.md]: {
          marginBottom: '2rem',
        },
      }}
    >
      <div
        // page__controls
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={{
          ...theme.mixins.col(1),
        }}
      >
        <div
          // main-block-controls-v2
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 0 8px',
          }}
        >
          <Link
            // main-block-controls-v2__title
            href={href}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              fontFamily: 'Montserrat',
              fontWeight: '700',
              fontSize: '2.43rem',
              display: 'flex',
              color: theme.colors.black,
              '&:hover': {
                color: theme.colors.red,
                textDecoration: 'underline',
                svg: {
                  fill: theme.colors.red,
                },
              },
              [DEVICE_SCREENS_MAX_STRING.md]: {
                fontSize: '24px',
              },
            }}
          >
            {title}
            <div>
              <AngleRightSVG
                css={{
                  width: 30,
                  height: '1.5rem',
                  fill: theme.colors.black,
                  [DEVICE_SCREENS_MAX_STRING.md]: {
                    width: 30,
                    height: 15,
                  },
                }}
              />
            </div>
          </Link>
        </div>
      </div>
      <div
        // scroll-wrapper
        css={{
          width: '100%',
          [DEVICE_SCREENS_MAX_STRING.sm]: {
            width: '100%',
            overflowX: 'scroll',
            whiteSpace: 'nowrap',
            paddingTop: 7,
            '&>*': {
              whiteSpace: 'normal',
            },
          },
        }}
      >
        <div
          // actions actions_scroll
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            [DEVICE_SCREENS_MAX_STRING.lg]: {
              gridTemplateColumns: '1fr 1fr 1fr',
            },
            [DEVICE_SCREENS_MAX_STRING.md]: {
              display: 'flex',
              flexWrap: 'nowrap',
              overflow: 'auto',
              paddingTop: 8,
              '::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              scrollbarWidth: 'none',
            },
          }}
        >
          {!!places?.results.length &&
            places.results.map((item) => (
              <PlaceCard
                key={item.id}
                place={item}
                css={{
                  [DEVICE_SCREENS_MAX_STRING.md]: {
                    minWidth: 'calc(100% / 2)',
                    minHeight: 400,
                  },
                  [DEVICE_SCREENS_MAX_STRING.sm]: {
                    minWidth: 'calc(100% / 1.3)',
                  },
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
