import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { unstable_serialize } from 'swr';
import { DEFAULT_REVALIDATION_TIME } from '../../lib/consts';
import { MainPage } from '../../components/pages/main-page';
import { fetcher, getDataFromApi } from '../../SWR/fetchs';
import { SWRPageWrapper } from '../../SWR/SWRPageWrapper';

export async function getStaticPaths() {
  const paths: string[] = [];
  return {
    paths,
    fallback: 'blocking',
  };
}

export async function getStaticProps(context: { params: { city: string } }) {
  const data = await getDataFromApi(context);
  return {
    props: {
      fallback: {
        [unstable_serialize([context.params.city])]: data,
      },
    },
    revalidate: DEFAULT_REVALIDATION_TIME,
  };
}

function Page({ fallback }: { fallback: object }) {
  return (
    <SWRPageWrapper
      fallback={fallback}
      component={MainPage}
      swrKeys={['city']}
      fetcher={fetcher}
    />
  );
}

export default Page;
