import { useTheme } from '@emotion/react';
import { ReactNode } from 'react';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';

function FavoriteButtonWrapper({
  children,
  fromSlider,
  fromMaterial,
  actionShip,
}: {
  children: ReactNode;
  fromSlider?: boolean;
  fromMaterial?: boolean;
  actionShip?: boolean;
}) {
  const theme = useTheme();
  return (
    <div
      css={{
        ...(fromSlider
          ? {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 40,
              height: 40,
              borderRadius: '50%',

              position: 'absolute',
              right: 24,
              bottom: 42,

              [DEVICE_SCREENS_MAX_STRING.md]: {
                maxHeight: 396,
                right: 16,
                bottom: 'auto',
                top: 24,

                width: 32,
                height: 32,
              },

              backgroundColor: theme.colors.white,
              opacity: 0.7,
            }
          : {}),
        ...(actionShip
          ? {
              display: 'flex',
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                position: 'absolute',
                right: 16,
                top: 26,
              },
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 40,
              minHeight: 40,
              maxWidth: 40,
              maxHeight: 40,
              borderRadius: '50%',

              [DEVICE_SCREENS_MAX_STRING.md]: {
                width: 32,
                height: 32,
              },

              backgroundColor: theme.colors.white,
              opacity: 0.7,
            }
          : {}),
        ...(fromMaterial
          ? {
              width: 32,
              height: 32,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 8,
              right: 12,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
              fontSize: 14,
              '.heart-icon': {
                width: 14,
                height: 14,
              },
            }
          : {}),
      }}
    >
      {children}
    </div>
  );
}

export default FavoriteButtonWrapper;
