import useSWR, { SWRConfig } from 'swr';
import React from 'react';
import { useRouter } from 'next/router';

function SWRDataProvider({
  Component,
  swrKeys,
  fetcher,
}: {
  Component: React.ElementType;
  swrKeys: string[];
  fetcher: (context: any) => void;
}) {
  const route = useRouter();
  const { data }: any = useSWR(
    Array.isArray(swrKeys) ? swrKeys.map((key) => route.query[key]) : swrKeys,
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    }
  );
  if (!data || typeof data !== 'object') {
    return null;
  }
  return <Component {...data} />;
}

export function SWRPageWrapper({
  fallback,
  component,
  ...props
}: {
  fallback: object | undefined;
  component: React.ElementType;
  swrKeys: string[];
  fetcher: (context: any) => void;
}) {
  return (
    <SWRConfig
      value={{
        fallback,
      }}
    >
      <SWRDataProvider Component={component} {...props} />
    </SWRConfig>
  );
}
