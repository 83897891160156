import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import BlogLogoSVG from '../../public/img/logo-blog.svg';
import { NewsItem } from './news/news-item';
import { GBButton } from '../styled/Button';
import { http } from '../../lib/api/http';
import { V2 } from '../../lib/api/urls';
import { activeStatus, orderingCatalog } from '../../constants/events';
import { City, Social, Stuff } from '../../models';
import { Material } from '../../models/material';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { Subscription } from './Subscription';
import { MobileApps } from './MobileApps';
import { getParamsFromSearch } from '../../utils';
import { useLoading } from '../../providers/loading';
import { BLOG_DOMAIN } from '../../lib/api/apiUrl';
import VkGroupWidget from '../vk-group-widget';

export function About({
  stuffList,
  newsList,
  currentCity,
  socials,
}: {
  stuffList: { results: Stuff[] };
  currentCity: City;
  newsList: { results: Material[]; next: string | null };
  socials: { results: Social[] };
}) {
  const theme = useTheme();
  const { updateLoading } = useLoading();
  // todo починить серверный рендеринг
  const [news, setNews] = useState<{
    results: Material[];
    next: string | null;
  } | null>(null);
  useEffect(() => {
    setNews(newsList);
  }, [newsList]);
  const onShowMoreNews = () => {
    const params = getParamsFromSearch(news!.next!);
    updateLoading(true);
    http
      .get(V2.materials, {
        is_web: true,
        city: currentCity.id,
        offset: params.offset,
        limit: 4,
        status: activeStatus,
        ordering: orderingCatalog.date,
      })
      .then((response: { results: Material[]; next: string | null }) => {
        setNews((oldState) => {
          if (oldState) {
            return {
              ...oldState,
              results: oldState.results.concat(response.results),
              next: response.next,
            };
          }
          return oldState;
        });
      })
      .finally(() => updateLoading(false));
  };
  return (
    <div>
      <div
        // page__recommend
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={{
          ...theme.mixins.row(),
          marginBottom: '4rem',
          [DEVICE_SCREENS_MAX_STRING.md]: {
            marginBottom: '2rem',
          },
        }}
      >
        <div
          // page__about
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={{
            ...theme.mixins.row(),
            marginBottom: '4rem',
            [DEVICE_SCREENS_MAX_STRING.md]: {
              marginBottom: '2rem',
              marginLeft: 8,
              marginRight: 8,
            },
          }}
        >
          <div
            // page__row
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              ...theme.mixins.row(),
              '> :nth-child(n)': {
                marginBottom: '8px',
                marginTop: '8px',
              },
            }}
          >
            {!!stuffList?.results.length &&
              stuffList.results.map((item) => (
                <div
                  // about__wrapper
                  css={{
                    position: 'relative',
                    width: '100%',
                    minHeight: '1px',
                    flex: '0 0 75%',
                    maxWidth: '75%',
                    flexGrow: '1',
                    paddingRight: 8,
                    paddingLeft: 8,
                    [DEVICE_SCREENS_MAX_STRING.md]: {
                      flex: 'unset',
                      maxWidth: '100%',
                    },
                  }}
                  key={item.id}
                >
                  <div
                    // card card_height_100
                    css={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '32px 60px',
                      backgroundColor: theme.colors.white,
                      color: theme.colors.text,
                      fontWeight: 400,
                      height: '100%',
                      borderRadius: 4,
                      [DEVICE_SCREENS_MAX_STRING.md]: {
                        padding: 16,
                      },
                    }}
                  >
                    <div>
                      <div
                        // card__title
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        css={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          fontSize: '18px',
                          lineHeight: '1.05',
                          marginBottom: '.5em',
                          display: item.icon ? 'flex' : 'block',
                          flexDirection: item.icon ? 'row' : '',
                          paddingRight: item.icon ? 0 : '150px',
                          [DEVICE_SCREENS_MAX_STRING.md]: {
                            marginBottom: 20,
                            marginRight: 50,
                            lineHeight: '1.4',
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <div
                        css={{
                          fontFamily: 'Golos',
                          marginBottom: 0,
                          [DEVICE_SCREENS_MAX_STRING.xl]: {
                            fontSize: '.9em',
                          },
                          [DEVICE_SCREENS_MAX_STRING.lg]: {
                            fontSize: '.8em',
                            lineHeight: '1.25',
                          },
                          [DEVICE_SCREENS_MAX_STRING.md]: {
                            fontSize: '14px',
                            lineHeight: '1.6',
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>
                    {!!item.icon && (
                      <svg
                        css={{
                          width: '20px',
                          height: '20px',
                          position: 'relative',
                          minWidth: '60px',
                          minHeight: '60px',
                          fill: theme.colors.red,
                          [DEVICE_SCREENS_MAX_STRING.lg]: {
                            minWidth: '42px',
                            minHeight: '42px',
                          },
                          [DEVICE_SCREENS_MAX_STRING.md]: {
                            position: 'absolute',
                            right: 16,
                            top: 16,
                          },
                        }}
                      >
                        <use xlinkHref={`#${item.icon}`} />
                      </svg>
                    )}
                  </div>
                </div>
              ))}
            <VkGroupWidget currentCity={currentCity} socials={socials} />
          </div>
        </div>
      </div>
      {!!news?.results?.length && (
        <div
          // page__blog-news
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={{
            marginBottom: '4rem',
            [DEVICE_SCREENS_MAX_STRING.md]: {
              marginBottom: '2rem',
            },
          }}
        >
          <div
            // page__row
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              '> :nth-child(n)': {
                marginBottom: '8px',
                marginTop: '8px',
              },
            }}
          >
            <div
            // page__controls
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            >
              <div
                // main-block-controls-v2
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0 0 8px',
                }}
              >
                <div
                  // page__controls_news
                  css={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    a: {
                      marginLeft: '1rem',
                    },
                  }}
                >
                  <div
                    // main-block-controls-v2__title
                    css={{
                      fontFamily: 'Montserrat',
                      fontWeight: '700',
                      fontSize: '2.43rem',
                      display: 'flex',
                      color: theme.colors.black,
                      [DEVICE_SCREENS_MAX_STRING.md]: {
                        fontSize: '1.8rem',
                      },
                    }}
                  >
                    Новости
                  </div>
                  <a
                    href={[BLOG_DOMAIN, 'blog', currentCity.url, ''].join('/')}
                    target='_blank'
                    aria-label='ссылка на сайт Горбилет блог'
                    rel='noopener noreferrer'
                  >
                    <BlogLogoSVG />
                  </a>
                </div>
                {/* {!!newsCategories?.results.length && ( */}
                {/*  <a */}
                {/*    // page__controls_news-more news__see-all_desktop */}
                {/*    css={{ */}
                {/*      display: 'flex', */}
                {/*      fontFamily: 'Montserrat', */}
                {/*      fontSize: '15px', */}
                {/*      fontWeight: 600, */}
                {/*      textDecoration: 'none', */}
                {/*      color: theme.colors.red, */}
                {/*      '&:hover': { */}
                {/*        color: theme.colors.red, */}
                {/*      }, */}
                {/*      [DEVICE_SCREENS_MAX_STRING.md]: { */}
                {/*        display: 'none', */}
                {/*      }, */}
                {/*    }} */}
                {/*    target='_blank' */}
                {/*    href={[ */}
                {/*      BLOG_DOMAIN, */}
                {/*      'blog', */}
                {/*      currentCity.url, */}
                {/*      'category', */}
                {/*      newsCategory.slug, */}
                {/*      '', */}
                {/*    ].join('/')} */}
                {/*    rel='noopener noreferrer' */}
                {/*  > */}
                {/*    Смотреть все */}
                {/*    <ArrowCornerSVG */}
                {/*      css={{ */}
                {/*        width: '20px', */}
                {/*        height: '20px', */}
                {/*        fill: theme.colors.red, */}
                {/*      }} */}
                {/*    /> */}
                {/*  </a> */}
                {/* )} */}
              </div>
            </div>
            <div
              // scroll-wrapper
              css={{
                width: '100%',
                [DEVICE_SCREENS_MAX_STRING.sm]: {
                  width: '100%',
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                  paddingTop: 7,
                  '&>*': {
                    whiteSpace: 'normal',
                  },
                },
              }}
            >
              <div
                // news
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                css={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr',
                  gap: 16,
                  [DEVICE_SCREENS_MAX_STRING.lg]: {
                    gridTemplateColumns: '1fr 1fr 1fr',
                  },
                  [DEVICE_SCREENS_MAX_STRING.md]: {
                    gridTemplateColumns: '1fr',
                  },
                }}
              >
                {news.results.map((newsItem) => (
                  <NewsItem
                    currentCity={currentCity}
                    key={newsItem.id}
                    item={newsItem}
                  />
                ))}
              </div>
              {news.next && (
                <div
                  // news-center-block
                  css={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <div onClick={onShowMoreNews}>
                    <GBButton
                      text='Показать ещё'
                      size='sm'
                      modification='shadow'
                      buttonStyle={{
                        height: '40px',
                        borderRadius: '19px',
                        width: '220px',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        fontSize: '15px',
                      }}
                      textStyle={{
                        width: '180px',
                        textAlign: 'center',
                      }}
                    />
                  </div>
                </div>
              )}
              {/* {!!newsCategories?.results.length && ( */}
              {/*  <a */}
              {/*    // page__controls_news-more news__see-all_desktop */}
              {/*    target='_blank' */}
              {/*    css={{ */}
              {/*      display: 'flex', */}
              {/*      justifyContent: 'center', */}
              {/*      fontFamily: 'Montserrat', */}
              {/*      fontSize: '15px', */}
              {/*      fontWeight: 600, */}
              {/*      textDecoration: 'none', */}
              {/*      color: theme.colors.red, */}
              {/*      marginTop: 24, */}
              {/*      '&:hover': { */}
              {/*        color: theme.colors.red, */}
              {/*      }, */}
              {/*      [DEVICE_SCREENS_MIN_STRING.md]: { */}
              {/*        display: 'none', */}
              {/*      }, */}
              {/*    }} */}
              {/*    href={[ */}
              {/*      BLOG_DOMAIN, */}
              {/*      'blog', */}
              {/*      currentCity.url, */}
              {/*      'category', */}
              {/*      newsCategory.slug, */}
              {/*      '', */}
              {/*    ].join('/')} */}
              {/*    rel='noreferrer' */}
              {/*  > */}
              {/*    Смотреть все */}
              {/*    <ArrowCornerSVG */}
              {/*      css={{ */}
              {/*        width: '20px', */}
              {/*        height: '20px', */}
              {/*        fill: theme.colors.red, */}
              {/*      }} */}
              {/*    /> */}
              {/*  </a> */}
              {/* )} */}
            </div>
          </div>
        </div>
      )}
      <div
        // page__misc
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 16,
          marginBottom: '4rem',
          [DEVICE_SCREENS_MAX_STRING.xl]: {
            marginBottom: '2rem',
            gridTemplateColumns: '1fr',
          },
        }}
      >
        <div
        // service__sub
        >
          <Subscription currentCity={currentCity} />
        </div>
        <div
        // service__app
        >
          <MobileApps />
        </div>
      </div>
    </div>
  );
}
