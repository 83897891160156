import { useEffect, useState } from 'react';
import { City, Social } from '../../models';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';

declare let VK: any;

function VkGroupWidget({
  socials,
  currentCity,
}: {
  socials: { results: Social[] };
  currentCity: City;
}) {
  const [isShowVk, setIsShowVk] = useState(false);
  function onScrolledIntoVKGroups(groupIdVK: string) {
    function subscriber(event: any) {
      const vkWidgetWrapper = event.currentTarget.getElementById('vk_groups');
      // because at the moment there is only one widget (VK group) and it is the width of the entire block
      const wrapperWidgets = document.querySelector('.social-widgets');
      if (wrapperWidgets) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        vkWidgetWrapper.style.width = `${wrapperWidgets.offsetWidth - 16}px`;
      }
      if (!vkWidgetWrapper) {
        event.currentTarget.removeEventListener('scroll', subscriber);
      } else if (
        window.scrollY >= vkWidgetWrapper.getBoundingClientRect().top
      ) {
        VK.Widgets.Group(
          'vk_groups',
          { mode: 3, no_cover: 1, width: 'auto', color1: 'FFFFFF' },
          groupIdVK
        );
        event.currentTarget.removeEventListener('scroll', subscriber);
      }
    }
    return subscriber;
  }
  useEffect(() => {
    async function initVk() {
      const vkInfo = socials.results.find((item) => item.type === `vk`);
      if (vkInfo) {
        let groupIdVK;
        if (vkInfo.comment === ``) {
          if (currentCity && currentCity.url) {
            if (currentCity.url === 'msk') {
              groupIdVK = '169812309';
            } else {
              groupIdVK = '97173519';
            }
          }
        } else {
          groupIdVK = vkInfo.comment;
        }
        if (groupIdVK) {
          setIsShowVk(true);
          const vkWidgetWrapper = document.getElementById('vk_groups');
          if (vkWidgetWrapper) {
            vkWidgetWrapper.innerHTML = '';
          }
          while (typeof VK === 'undefined') {
            // eslint-disable-next-line no-await-in-loop,no-promise-executor-return
            await new Promise((resolve) => setTimeout(resolve, 999));
          }
          document.addEventListener(
            'scroll',
            onScrolledIntoVKGroups(groupIdVK)
          );
        } else {
          // this.isShowVk = false;
        }
      }
    }
    initVk();
  }, [socials, currentCity]);
  if (!isShowVk) {
    return null;
  }
  return (
    <div
      // social-widgets__wrapper
      css={{
        position: 'relative',
        width: '25%',
        minHeight: '1px',
        flex: '1 0 25%',
        paddingRight: 8,
        paddingLeft: 8,
        [DEVICE_SCREENS_MAX_STRING.md]: {
          marginTop: 32,
          flex: '0 0 100%',
          maxWidth: '100%',
        },
      }}
    >
      <div
        // card_height_100
        css={{
          height: '100%',
        }}
      >
        <div
          // card card_height_100
          css={{
            padding: 0,
            height: '100%',
            position: 'relative',
            backgroundColor: '#fff',
            color: '#000',
            borderRadius: 4,
            textAlign: 'left',
            fontWeight: 500,
          }}
        >
          <div
            // card__text card__text_page_main_bottom
            css={{
              marginBottom: '1em',
              [DEVICE_SCREENS_MAX_STRING.lg]: {
                marginBottom: 0,
              },
            }}
          >
            <div
              className='social-widgets'
              css={{
                fontWeight: 400,
                display: 'flex',
                justifyContent: 'flex-start',
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, "Noto Sans Armenian", "Noto Sans Bengali", "Noto Sans Cherokee", "Noto Sans Devanagari", "Noto Sans Ethiopic", "Noto Sans Georgian", "Noto Sans Hebrew", "Noto Sans Kannada", "Noto Sans Khmer", "Noto Sans Lao", "Noto Sans Osmanya", "Noto Sans Tamil", "Noto Sans Telugu", "Noto Sans Thai", sans-serif',
                '> div': {
                  margin: 8,
                },
              }}
            >
              <div id='vk-widget__container'>
                <div
                  id='vk_groups'
                  // todo temporary solution
                  css={{
                    iframe: {
                      ':not(:first-child)': {
                        display: 'none',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VkGroupWidget;
