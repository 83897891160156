import async from 'async';
import { http } from '../../lib/api/http';
import { V2, V3, V4 } from '../../lib/api/urls';
import {
  activeStatus,
  displayInBlock,
  eventStatuses,
  eventTypes,
  orderingCatalog,
} from '../../constants/events';
import {
  Action,
  City,
  Compilation,
  Material,
  Page,
  Place,
  Social,
  Stuff,
} from '../../models';
import { AdpCategories, Category } from '../../models/categories';
import { Contact } from '../../models/contact';
import { MainSlide, Slide } from '../../models/city';
import { BlogCategories } from '../../models/blog-categories';

export async function getDataFromApi(context: { params: { city: string } }) {
  const cities: { results: City[] } = await http.get(V3.cities);
  const currentCity =
    cities.results.find((item) => item.url === context.params.city) ||
    cities.results[0];
  // TODO В данной библиотеке функция возвращает `void`;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data: {
    topCompilations: { results: Compilation[] };
    compilations: { results: Compilation[] };
    popularActions: { results: Action[] };
    newActions: { results: Action[] };
    places: { results: Place[] };
    stuffList: { results: Stuff[] };
    pageList: { results: Page[] };
    adpCategories: { categories: AdpCategories };
    socials: { results: Social[] };
    socialsLinks: { results: Social[] };
    contactList: { results: Contact[] };
    eventSlides: { results: Slide[] };
    parentCategories: { results: Category[] };
    newsCategories: { results: BlogCategories[] };
    newsList: { result: Material[] };
    mainSlide: MainSlide;
  } = await async.parallel({
    topCompilations: (callback) =>
      http
        .get(V3.compilations.root, {
          city: currentCity.id,
          is_web: true,
          is_top: true,
          offset: 0,
          limit: 8,
          status: eventStatuses.active,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    compilations: (callback) =>
      http
        .get(V3.compilations.root, {
          city: currentCity.id,
          is_web: true,
          is_index: true,
          offset: 0,
          limit: 8,
          status: eventStatuses.active,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    popularActions: (callback) =>
      http
        .get(V4.events, {
          ordering: orderingCatalog.popular,
          status: eventStatuses.active,
          exclude_type: eventTypes.free,
          is_web: true,
          city: currentCity.id,
          limit: 8,
          offset: 0,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    newActions: (callback) =>
      http
        .get(V4.events, {
          ordering: orderingCatalog.new,
          status: eventStatuses.active,
          is_web: true,
          city: currentCity.id,
          limit: 8,
          offset: 0,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    places: (callback) =>
      http
        .get(V2.places, {
          is_web: true,
          city: currentCity.id,
          show_on_index: true,
          offset: 0,
          limit: 8,
          status: eventStatuses.active,
          ordering: orderingCatalog.views,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    stuffList: (callback) =>
      http
        .get(V2.recommendations, {
          status: activeStatus,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    pageList: (callback) =>
      http
        .get(V2.pages, {
          city: null,
          status: activeStatus,
          show_in_footer: true,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    socials: (callback) =>
      http
        .get(V2.socials, {
          display_in_block: displayInBlock.contactUs,
          status: activeStatus,
          city: currentCity.id,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    socialsLinks: (callback) =>
      http
        .get(V2.socials, {
          display_in_block: displayInBlock.weInSocials,
          status: activeStatus,
          city: currentCity.id,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    contactList: (callback) =>
      http
        .get(V2.contacts, {
          limit: 9e9,
          status: activeStatus,
          city: currentCity.id,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    eventSlides: (callback) =>
      http
        .get(`${V3.cities + currentCity.id}/event-slides/`)
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    mainSlide: (callback) =>
      http
        .get(`${V3.cities + currentCity.id}/main-slide/`)
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    adpCategories: (callback) =>
      http
        .get(`${V3.cities + currentCity.id}/adp-categories/`)
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    parentCategories: (callback) =>
      http
        .get(V3.categories, {
          city: currentCity.id,
          is_web: true,
          status: activeStatus,
          sort: 'sortOrder',
          limit: 1000,
          level: 0,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
    newsList: (callback) =>
      http
        .get(V2.materials, {
          is_web: true,
          city: currentCity.id,
          offset: 0,
          limit: 4,
          status: activeStatus,
          ordering: orderingCatalog.date,
        })
        .then((res) => callback(null, res))
        .catch((err) => callback(null, { error: err })),
  });

  const categoriesForHeader = [];

  for (let i = 0; i < data.adpCategories.categories.length; i += 1) {
    const el = data.parentCategories.results.find(
      (item) => item.id === data.adpCategories.categories[i]
    );
    if (el) {
      categoriesForHeader.push(el);
    }
  }

  // TODO config сюда же;
  return {
    currentCity,
    cities,
    topCompilations: data.topCompilations,
    compilations: data.compilations,
    popularActions: data.popularActions,
    newActions: data.newActions,
    places: data.places,
    stuffList: data.stuffList,
    mainSlide: data.mainSlide,
    eventSlides: data.eventSlides,
    categoriesForHeader,
    newsList: data.newsList,
    socialsLinks: data.socialsLinks,
    footerProps: {
      pageList: data.pageList,
      socials: data.socials,
      socialsLinks: data.socialsLinks,
      contactList: data.contactList,
    },
  };
}

export async function fetcher(context: string[]) {
  return getDataFromApi({ params: { city: context[0] } });
}
