import { useTheme } from '@emotion/react';
import AngleRightSVG from '../../public/img/angle-right.svg';
import { CompilationCard } from './compilation-card';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { Link } from '../styled/Link';
import { City, Compilation } from '../../models';

export function CompilationsBottom({
  compilations,
  title,
  href,
  currentCity,
}: {
  title: string;
  compilations: { results: Compilation[] };
  currentCity: City;
  href: string;
}) {
  const theme = useTheme();
  if (!compilations?.results.length) {
    return null;
  }
  return (
    <div
      // page__compilations-bottom
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        ...theme.mixins.row(),
        marginBottom: '4rem',
        [DEVICE_SCREENS_MAX_STRING.sm]: {
          display: 'none',
        },
      }}
    >
      <div
        // page__controls
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={{
          ...theme.mixins.col(1),
        }}
      >
        <div // main-block-controls-v2
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 0 8px',
          }}
        >
          <Link
            // main-block-controls-v2__title
            href={href}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              fontFamily: 'Montserrat',
              fontWeight: '700',
              fontSize: '2.43rem',
              display: 'flex',
              color: theme.colors.black,
              '&:hover': {
                color: theme.colors.red,
                textDecoration: 'underline',
                svg: {
                  fill: theme.colors.red,
                },
              },
            }}
          >
            {title}
            <div>
              <AngleRightSVG
                width={30}
                height='1.5rem'
                fill={theme.colors.black}
              />
            </div>
          </Link>
        </div>
      </div>
      <div
        // compilations
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={{
          ...theme.mixins.flex(),
        }}
      >
        {compilations.results.map((compilation) => (
          <div
            key={`CompilationWrapper__${compilation.id}`}
            css={{
              position: 'relative',
              width: '100%',
              minHeight: '1px',
              flex: '0 0 25%',
              maxWidth: '25%',
              paddingRight: '8px',
              paddingLeft: '8px',
              marginBottom: '16px',
              '&:hover': {
                color: theme.colors.white,
              },
            }}
          >
            <CompilationCard
              key={`CompilationCard__${compilation.id}`}
              item={compilation}
              currentCity={currentCity}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
