import { Carousel } from 'react-responsive-carousel';
import { useTheme } from '@emotion/react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { splitPriceByThree } from '../../utils';
import { DEVICE_SCREENS, DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { City, Stuff } from '../../models';
import FavoriteButtonWrapper from '../favorite/FavoriteButtonWrapper';
import FavoriteButton from '../favorite/FavoriteButton';
import { FavoriteKinds } from '../../models/favorite';
import { MainSlide, Slide } from '../../models/city';
import { Image } from '../picture/picture';
import { Link } from '../styled/Link';

export function MainSlider({
  eventSlides,
  mainSlide,
  stuffList,
  currentCity,
}: {
  eventSlides: Slide[];
  mainSlide: MainSlide;
  currentCity: City;
  stuffList: { results: Stuff[] };
}) {
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const [startPlaySlider, setStartPlaySlider] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= DEVICE_SCREENS.md);
  }, []);

  useLayoutEffect(() => {
    setStartPlaySlider(true);
  }, []);

  const [isShownCommercialPopover, setIsShownCommercialPopover] =
    useState(false);
  const theme = useTheme();
  if (mainSlide?.image === null && eventSlides?.length === 0) {
    return null;
  }
  const hiddenStuffItem = stuffList?.results.find(
    (item) => item.show_above_other
  );
  const mapSlides = () => {
    const slides = [];
    if (mainSlide?.webp || mainSlide?.image) {
      slides.push(
        <div
          key={`MainSlide__${mainSlide.id}`}
          css={{
            width: '100%',
            height: '570px',
            position: 'relative',
            '@media(max-width: 1329px)': {
              maxHeight: '507px',
            },
            [DEVICE_SCREENS_MAX_STRING.lg]: {
              height: '317px',
            },
            [DEVICE_SCREENS_MAX_STRING.md]: {
              height: '374px',
            },
          }}
        >
          <div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              height: '100%',
              ...theme.mixins.responsiveBoxInside,
              [DEVICE_SCREENS_MAX_STRING.md]: {
                height: '100%',
              },
            }}
          >
            <img
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              loading='eager'
              alt={mainSlide.title}
              src={mainSlide.webp || mainSlide.image}
            />
          </div>
          <div
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              overflow: 'hidden',
              color: theme.colors.white,
              padding: '40px 40% 100px 70px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              textAlign: 'left',
              background:
                'linear-gradient(180deg, rgba(19, 19, 19, 0) 25.52%, #131313 100%)',
              [DEVICE_SCREENS_MAX_STRING.lg]: {
                padding: '40px 10% 60px 40px',
              },
              [DEVICE_SCREENS_MAX_STRING.md]: {
                padding: '40px 20px 60px 24px',
              },
            }}
          >
            <Link css={{ cursor: 'pointer' }} href={mainSlide.href}>
              <h2
                css={{
                  fontSize: '34px',
                  fontFamily: 'Golos',
                  fontWeight: '700',
                  lineHeight: 1,
                  marginTop: '30px',
                  marginBottom: '40px',
                  color: theme.colors.textWhite,
                  [DEVICE_SCREENS_MAX_STRING.lg]: {
                    fontSize: '26px',
                    marginBottom: '20px',
                  },
                }}
              >
                {mainSlide.title}
              </h2>
            </Link>
            <span
              css={{
                fontSize: '18px',
                fontFamily: 'Golos',
                fontWeight: '500',
                lineHeight: 1.4,
                color: theme.colors.textWhite,
                [DEVICE_SCREENS_MAX_STRING.lg]: {
                  fontSize: '14px',
                },
              }}
            >
              {mainSlide.description}
            </span>
          </div>
        </div>
      );
    }
    if (eventSlides?.length) {
      slides.push(
        eventSlides.map((slide, index) => (
          <div
            key={slide.id}
            css={{
              width: '100%',
              height: '570px',
              position: 'relative',
              color: theme.colors.textWhite,
              transition: 'opacity .5s ease',
              borderRadius: '0 0 4px 4px',
              overflow: 'hidden',
              background:
                'linear-gradient(180deg, rgba(19, 19, 19, 0) 25.52%, #131313 100%)',
              img: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: '100%',
                height: '100%',
                objectFit: 'cover',
              },
              '@media(max-width: 1329px)': {
                maxHeight: '507px',
              },
              [DEVICE_SCREENS_MAX_STRING.lg]: {
                height: '317px',
              },
              [DEVICE_SCREENS_MAX_STRING.md]: {
                height: '374px',
              },
            }}
          >
            <Image
              key={`img__${slide.id}`}
              image={slide.images[0]}
              loading={!mainSlide.id && index === 0 ? 'eager' : 'lazy'}
              alt={slide.title}
            />
            {slide.creative_ad_id && (
              <div
                onMouseEnter={() => setIsShownCommercialPopover(true)}
                onMouseLeave={() => setIsShownCommercialPopover(false)}
                css={{
                  zIndex: 999,
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: 1.3,
                  display: 'flex',
                  padding: '4px 8px',
                  gap: 10,
                  width: 'fit-content',
                  position: 'absolute',
                  left: 16,
                  top: 16,
                  background: 'rgba(40, 40, 40, 0.7)',
                  backdropFilter: 'blur(2px)',
                  borderRadius: 4,
                }}
              >
                <div
                  css={{
                    cursor: 'default',
                    position: 'relative',
                  }}
                >
                  Реклама
                  {(slide.sponsor.inn || slide.sponsor.legal_entity_name) && (
                    <div
                      css={{
                        position: 'absolute',
                        display: isShownCommercialPopover ? 'flex' : 'none',
                        flexDirection: 'column',
                        padding: '8px 12px',
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: 1.3,
                        top: '28px !important',
                        left: '-6px !important',
                        color: '#282828',
                        background: '#FFFFFF',
                        borderRadius: 4,
                      }}
                    >
                      {slide.sponsor.legal_entity_name && (
                        <div css={{ width: 'max-content' }}>
                          {slide.sponsor.legal_entity_name}
                        </div>
                      )}
                      {slide.sponsor.inn && (
                        <div css={{ width: 'max-content' }}>
                          ИНН: {slide.sponsor.inn}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              css={{
                textAlign: 'left',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: '20px 20% 20px 70px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                zIndex: 1,
                background:
                  'linear-gradient(180deg, rgba(19, 19, 19, 0) 25.52%, #131313 100%)',
                [DEVICE_SCREENS_MAX_STRING.lg]: {
                  padding: '20px 20% 20px 40px',
                },
                [DEVICE_SCREENS_MAX_STRING.md]: {
                  padding: '20px 10px 40px 20px',
                },
              }}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                {!!slide.discount && (
                  <div
                    css={{
                      display: 'flex',
                      backgroundColor: '#f44336',
                      padding: '3px 9px',
                      fontFamily: 'Golos',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '13px',
                      lineHeight: '140%',
                      color: theme.colors.textWhite,
                    }}
                  >
                    <span>
                      <b>Скидка {slide.discount}%</b>
                    </span>
                  </div>
                )}
              </div>
              <Link
                href={[
                  ['', currentCity.url, 'actions', slide.slug, ''].join('/'),
                  slide.creative_ad_id ? `?erid=${slide.creative_ad_id}` : '',
                ].join('')}
                css={{
                  '&:hover': { color: theme.colors.textWhite },
                  fontSize: '26px',
                  marginBottom: '10px',
                  textDecoration: 'none',
                  color: theme.colors.textWhite,
                  fontFamily: 'Montserrat, Arial',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  lineHeight: '120%',
                  maxWidth: '500px',
                  [DEVICE_SCREENS_MAX_STRING.md]: {
                    fontSize: '20px',
                  },
                }}
              >
                <p>{slide.title}</p>
              </Link>
              <div
                css={{
                  fontFamily: 'Golos, Arial',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '13px',
                  lineHeight: '100%',
                  color: theme.colors.textWhite,
                  marginBottom: '8px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {slide.dates_txt} &bull; {slide.place_title}
              </div>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  css={{
                    marginBottom: '4px',
                    fontSize: '18px',
                    lineHeight: 1,
                  }}
                >
                  {!!slide.instead_price && (
                    <small
                      css={{
                        position: 'relative',
                        fontFamily: 'Golos',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        lineHeight: '100%',
                        color: theme.colors.textWhite,
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          width: '100%',
                          height: '1px',
                          backgroundColor: '#F44336',
                          margin: 'auto',
                        },
                      }}
                    >
                      {splitPriceByThree(slide.instead_price)}
                    </small>
                  )}
                  {!!slide.filter_price && (
                    <span
                      css={{
                        fontFamily: 'Golos',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '100%',
                        color: '#F44336',
                        marginLeft: slide.instead_price ? '5px' : 0,
                      }}
                    >
                      {splitPriceByThree(slide.filter_price)} ₽
                    </span>
                  )}
                </div>
              </div>
              <FavoriteButtonWrapper fromSlider>
                <FavoriteButton
                  kind={FavoriteKinds.event}
                  id={slide.remote_id}
                  size='medium'
                  variant='red-outline'
                  fromSlider
                />
              </FavoriteButtonWrapper>
            </div>
          </div>
        ))
      );
    }
    return slides;
  };
  return (
    <div
      css={{
        marginBottom: '3rem',
      }}
    >
      {hiddenStuffItem && (
        <h1 css={{ display: 'none' }}>{hiddenStuffItem.title}</h1>
      )}
      <Carousel
        css={{
          overflow: 'hidden',
          maxHeight: '570px',
          display: 'flex',
          borderRadius: '0 0 4px 4px',
          '@media(max-width: 1329px)': {
            maxHeight: '507px',
          },
          [DEVICE_SCREENS_MAX_STRING.lg]: {
            maxHeight: '317px',
          },
          [DEVICE_SCREENS_MAX_STRING.md]: {
            maxHeight: '374px',
          },
        }}
        autoPlay={startPlaySlider}
        interval={5000}
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        showArrows={!isMobile}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {mapSlides()}
      </Carousel>
    </div>
  );
}
