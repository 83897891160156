import { useTheme } from '@emotion/react';
import { DateTime } from 'luxon';
import { Picture } from '../../picture/picture';
import { Material } from '../../../models/material';
import { BLOG_DOMAIN } from '../../../lib/api/apiUrl';
import { City } from '../../../models';

export function NewsItem({
  item,
  currentCity,
}: {
  item: Material;
  currentCity: City;
}) {
  const theme = useTheme();
  const onFormatDate = (date: string) =>
    DateTime.fromISO(date).setLocale('ru').toFormat('dd MMMM yyyy');
  return (
    <div
      // news__item
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        marginBottom: '16px',
      }}
    >
      <div
      // app-news-list-item
      >
        <div
          // news-card
          css={{
            height: '100%',
            position: 'relative',
            top: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            'a:hover': {
              color: theme.colors.text,
            },
          }}
        >
          <a
            // news-card__image-wrapper
            href={[
              BLOG_DOMAIN,
              'blog',
              currentCity.url,
              item.blog_categories[0].slug,
              item.slug,
              '',
            ].join('/')}
            target='_blank'
            css={{
              height: 0,
              width: '100%',
              paddingBottom: '65%',
              position: 'relative',
              display: 'block',
            }}
            rel='noopener noreferrer'
          >
            <div
              // news-card__image
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              css={{
                ...theme.mixins.absoluteInsideBox,
              }}
            >
              <Picture
                src={item.animated_cover || item.jpg_card || item.webp_card}
                alt={item.title}
              />
            </div>
          </a>
          <div
            // news-card__info
            css={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: '8px',
              padding: '12px  0',
              fontFamily: 'Golos',
            }}
          >
            <div
              // news-card__sub-info
              css={{
                color: '#7D7B7A',
              }}
            >
              {item.blog_categories[0].title} |{' '}
              {onFormatDate(item.published_at)}{' '}
            </div>
            <a
              href={[
                BLOG_DOMAIN,
                'blog',
                currentCity.url,
                item.blog_categories[0].slug,
                item.slug,
                '',
              ].join('/')}
              target='_blank'
              css={{
                fontFamily: 'Golos, Arial, serif',
                fontWeight: 600,
                fontSize: '14px',
                color: theme.colors.text,
              }}
              rel='noopener noreferrer'
            >
              {item.title}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
