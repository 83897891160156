import { useTheme } from '@emotion/react';
import { Container } from './Container';
import { Compilations } from '../compilations/compilations';
import { Posts } from '../posts/posts';
import { MainSlider } from '../main-slider/main-slider';
import { Places } from '../places/places';
import { Viewed } from '../viewed/viewed';
import { About } from '../about/about';
import { CompilationsBottom } from '../compilations/compilations-bottom';
import { Action, City, Compilation, Place, Social, Stuff } from '../../models';
import { Material } from '../../models/material';
import { BlogCategories } from '../../models/blog-categories';
import { MainSlide, Slide } from '../../models/city';

export function Main({
  currentCity,
  eventSlides,
  mainSlide,
  topCompilations,
  compilations,
  popularActions,
  newActions,
  places,
  viewed,
  stuffList,
  newsList,
  socialsLinks,
  className,
}: {
  currentCity: City;
  eventSlides: { results: Slide[] };
  mainSlide: MainSlide;
  topCompilations: { results: Compilation[] };
  compilations: { results: Compilation[] };
  popularActions: { results: Action[] };
  newActions: { results: Action[] };
  places: { results: Place[] };
  viewed: { results: Action[] } | null;
  stuffList: { results: Stuff[] };
  newsList: { results: Material[]; next: string | null };
  socialsLinks: { results: Social[] };
  className?: string;
}) {
  const theme = useTheme();
  return (
    <main className={className || ''}>
      <Container
        css={{ paddingLeft: '8px', paddingRight: '8px' }}
        className='main-container'
      >
        <MainSlider
          eventSlides={eventSlides?.results}
          mainSlide={mainSlide}
          stuffList={stuffList}
          currentCity={currentCity}
        />
        <div
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={{
            ...theme.mixins.col(1),
            padding: 0,
            marginBottom: '2rem',
          }}
        >
          <Compilations
            scroll
            href={['', currentCity.url, 'compilations', ''].join('/')}
            currentCity={currentCity}
            title='Лучшие подборки'
            compilations={topCompilations}
          />
        </div>
        <Posts
          title='Популярное'
          actions={popularActions}
          currentCity={currentCity}
          href={['', currentCity.url, 'catalog', ''].join('/')}
        />
        <Posts
          title='Новое'
          actions={newActions}
          currentCity={currentCity}
          href={['', currentCity.url, 'catalog/?sort=new'].join('/')}
        />
        <CompilationsBottom
          title='Подборки'
          compilations={compilations}
          currentCity={currentCity}
          href={['', currentCity.url, 'compilations', ''].join('/')}
        />
        <Places
          title='Места'
          places={places}
          href={['', currentCity.url, 'place-catalog', ''].join('/')}
        />
        {viewed && (
          <Viewed
            title='Вы смотрели'
            viewed={viewed}
            currentCity={currentCity}
          />
        )}
        <About
          socials={socialsLinks}
          currentCity={currentCity}
          stuffList={stuffList}
          newsList={newsList}
        />
      </Container>
    </main>
  );
}
